<script setup>
import UserDropdown from "@/Components/Layout/Navbar/UserDropdown.vue";
import NotificationDropdown from "@/Components/Layout/Navbar/NotificationDropdown.vue";
import {ref, watch} from "vue";
import {UserCircleIcon} from '@heroicons/vue/24/outline';
import SearchFormSearchPage from "@/Components/Layout/Navbar/SearchFormSearchPage.vue";
import {isMobile as isMob} from "@/Composables/deviceUtils"
import ArrowLongRightIcon from "@/Icons/ArrowLongRightIcon.vue";

const props = defineProps({
    currentPage: String
});

const showNavbar = ref(false)
const pagesSubmenuOpen = ref(false)

const isSearchPage = ref(route().current() === 'search')
const isMobile = isMob()

watch(() => props.currentPage, (newVal, oldValue) => {
    isSearchPage.value = newVal === 'search'

    if (newVal !== oldValue) {
        showNavbar.value = false
    }
})
</script>

<template>
    <nav class="border-b py-2" v-if="isMobile && !$page.props.auth.user">
        <Link class="flex justify-center" :href="route('register-company')">{{ __('register company') }}
        <span class="ml-3"><ArrowLongRightIcon class="w-5 h-5" /></span>
        </Link>
    </nav>
    <header class="navbar-light header-sticky">
        <!-- Logo Nav START -->
        <nav class="navbar navbar-expand-xl">
            <div class="container">
                <!-- Logo START -->
                <Link class="navbar-brand flex items-center" href="/">
                    <img class="light-mode-item navbar-brand-item" src="/images/logo_4_sm.jpg" alt="logo"
                         style="height: 55px;"/>
                    <img class="dark-mode-item navbar-brand-item" src="/images/logo_4_sm.jpg" alt="logo"/>
                    <span class="bold fs-4 ml-2" :class="{'d-none d-lg-block': isSearchPage}">Buch Termin</span>
                </Link>
                <!-- Logo END -->

                <!-- Responsive navbar toggler -->
                <button class="navbar-toggler ms-auto mr-4 ms-sm-0 p-0 p-sm-2 collapsed" type="button"
                        data-bs-toggle="collapse"
                        @click.prevent="showNavbar = !showNavbar" data-bs-target="#navbarCollapse"
                        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-animation">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <!-- <span class="d-none d-sm-inline-block small"></span> -->
                </button>

                <!-- Main navbar START -->
                <div class="navbar-collapse" id="navbarCollapse"
                     :class="{ 'block': showNavbar, 'collapse': !showNavbar }" v-show="!isSearchPage">
                    <ul class="navbar-nav navbar-nav-scroll me-auto block d-lg-none">

                        <!-- Nav item Pages -->
<!--                        <li class="nav-item dropdown">-->
<!--                            <a class="nav-link dropdown-toggle" href="#" id="pagesMenu" data-bs-toggle="dropdown"-->
<!--                               aria-haspopup="true" aria-expanded="false" @click.prevent="pagesSubmenuOpen = !pagesSubmenuOpen">-->
<!--                                Pages-->
<!--                            </a>-->
<!--                            <ul class="dropdown-menu" :class="{'show': pagesSubmenuOpen}" aria-labelledby="pagesMenu">-->

<!--                            </ul>-->
<!--                        </li>-->

                        <li class="nav-item">
                            <Link class="nav-link capitalize" :href="route('homepage')">{{ __('homepage') }}</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link capitalize" :href="route('about')">{{ __('about') }}</Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" :href="route('contact')">
                                {{ __('Contact') }}
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" :href="route('pricing')">
                                {{ __('Pricing') }}
                            </Link>
                        </li>
                        <li class="nav-item">
                            <Link class="nav-link" :href="route('faq')">
                                {{ __('FAQ') }}
                            </Link>
                        </li>

                    </ul>
                </div>
                <!-- Main navbar END -->

                <div v-if="isSearchPage" class="w-[330px] mx-auto shrink-0 d-none d-lg-block">
                    <SearchFormSearchPage />
                </div>

                <!-- Profile and Notification START -->
                <ul v-if="$page.props.auth.user" class="nav flex-row align-items-center list-unstyled ms-xl-auto">
                    <!-- Notification dropdown START -->
                    <NotificationDropdown :notifications="$page.props.auth.user.notifications"/>
                    <!-- Notification dropdown END -->

                    <UserDropdown :user="$page.props.auth.user"/>
                </ul>
                <!-- Profile and Notification START -->

                <ul v-if="!$page.props.auth.user" class="nav flex-row align-items-center list-unstyled ms-xl-auto">
                    <li class="nav-item">
                        <Link class="nav-link font-medium text-dark" :href="route('login')">
                            <span class="hidden md:block"> {{ __('Login') }}</span>
                            <span class="block md:hidden">
                                <UserCircleIcon class="w-7 h-7"/>
                            </span>
                        </Link>
                    </li>
                    <li class="nav-item hidden md:block">
                        <Link class="nav-link font-medium text-dark " :href="route('register-company')">
                            {{
                                __('register company')
                            }}
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- Logo Nav END -->
    </header>
</template>
