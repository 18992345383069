<script setup>

import {usePage} from "@inertiajs/vue3";
import {computed} from "vue";

defineProps({
    flash: {
        type: Object,
        required: false
    }
})

const flash = computed(() => usePage().props.flash)

</script>

<template>
    <div v-if="flash.error" class="alert alert-danger">
        {{ flash.error }}
    </div>

    <div v-if="flash.success" class="alert alert-success">
        {{ flash.success }}
    </div>
    <div v-if="flash.message" class="alert alert-info">
        {{ flash.message }}
    </div>
</template>

<style scoped>

</style>
