<script setup>

import {
    ArchiveBoxXMarkIcon,
    BeakerIcon,
    BriefcaseIcon,
    CalendarDaysIcon,
    Cog8ToothIcon,
    HomeIcon
} from "@heroicons/vue/24/outline";

import { ref } from "vue";
import GuestLayout from "./GuestLayout.vue";
import NewBookingButton from '@/Components/CompanyBooking/NewBookingButton.vue';
import {useUser} from "@/Composables/useUser.js";

const user = useUser()
const showDashboardMenu = ref(false)


</script>

<template>
    <GuestLayout>
        <section class="pt-4">
            <div class="container">
                <div class="card rounded-3 border p-3 pb-2">
                    <!-- Avatar and info START -->
                    <div class="d-sm-flex align-items-center">
                        <div class="avatar avatar-xl mb-2 mb-sm-0">
                            <img class="avatar-img rounded-circle" :src="user.img_path" alt="">
                        </div>

                        <h4 class="mb-2 mb-sm-0 ms-sm-3"> {{ user.first_name + ' ' + user.last_name }}</h4>

                        <NewBookingButton class="btn-sm ms-auto" href="#" />
                    </div>
                    <!-- Avatar and info START -->

                    <!-- Responsive navbar toggler -->
                    <button class="btn btn-primary w-100 d-block d-xl-none mt-2" type="button"
                        @click.prevent="showDashboardMenu = !showDashboardMenu">
                        <i class="bi bi-list"></i> Dashboard Menu
                    </button>

                    <!-- Nav links START -->
                    <div class="offcanvas-xl offcanvas-end mt-xl-3" :class="{ 'show': showDashboardMenu }" tabindex="-1"
                        id="dashboardMenu">
                        <div class="offcanvas-header border-bottom p-3">
                            <h5 class="offcanvas-title">Menu</h5>
                            <button type="button" class="btn-close" @click.prevent="showDashboardMenu = false"
                                aria-label="Close"></button>
                        </div>
                        <!-- Offcanvas body -->
                        <div class="offcanvas-body p-3 p-xl-0">
                            <!-- Nav item -->
                            <div class="navbar navbar-expand-xl">
                                <ul class="navbar-nav navbar-offcanvas-menu">

                                    <li class="nav-item">
                                        <Link class="nav-link space-x-2" :class="{ 'active': route().current('dashboard') }"
                                            :href="route('dashboard')">
                                        <HomeIcon class="w-5 h-5" />
                                        <span>Dashboard</span>
                                        </Link>
                                    </li>

                                    <li class="nav-item">
                                        <Link class="nav-link space-x-2"
                                            :class="{ 'active': route().current('employee.booking.index') }"
                                            :href="route('employee.booking.index')">
                                        <CalendarDaysIcon class="w-5 h-5" />
                                        <span>{{ __('Bookings') }}</span>
                                        </Link>
                                    </li>

                                    <li class="nav-item">
                                        <Link class="nav-link space-x-2"
                                            :class="{ 'active': route().current('employee.service.index') }"
                                            :href="route('employee.service.index')">
                                        <BeakerIcon class="w-5 h-5" />
                                        <span>{{ __('Services') }}</span>
                                        </Link>
                                    </li>

                                    <li class="nav-item">
                                        <Link class="nav-link space-x-2"
                                            :class="{ 'active': route().current('employee.schedule.index') }"
                                            :href="route('employee.schedule.index')">
                                        <BriefcaseIcon class="w-5 h-5" />
                                        <span>{{ __('Schedules') }}</span>
                                        </Link>
                                    </li>

                                    <li class="nav-item">
                                        <Link class="nav-link space-x-2" :href="route('employee.absence.index')">
                                        <ArchiveBoxXMarkIcon class="w-5 h-5" />
                                        <span>{{ __('Absences') }}</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link class="nav-link space-x-2"
                                              :class="{ 'active': route().current('employee.settings.index') }"
                                              :href="route('employee.settings.index')">
                                        <Cog8ToothIcon class="w-5 h-5" />
                                        <span>{{ __('Settings') }}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <!-- Nav links END -->
                </div>
            </div>
        </section>
        <!-- ======================= Menu item END -->


        <slot />
    </GuestLayout>
</template>

<style scoped></style>
