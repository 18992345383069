<script setup>

import {__} from "@/Composables/translate";
import { router } from "@inertiajs/vue3";
import { vOnClickOutside } from "@vueuse/components";
import {onMounted, ref, watch} from "vue";
import SearchIcon from "@/Icons/SearchIcon.vue";
import { CalendarDaysIcon, MapPinIcon } from "@heroicons/vue/24/outline";
import axios from "axios";
import VueFlatpickr from "@/Components/Welcome/VueFlatpickr.vue";
import IconClose from "@/Components/Icons/IconClose.vue";
import {useSearchStore} from "@/stores/searchStore";

const store = useSearchStore()

onMounted(() => {
    let routeParams = route().params
    if (routeParams.city) {
        store.location = routeParams.city
    }
    if (routeParams.category) {
        store.search = routeParams.category
    }
})



const isExpanded = ref(false)
function expandSearch() {
    isExpanded.value = true
}

const categories = [
    "Friseur",
    "Nagelstudio",
    "Enthaarungssalon",
    "Schönheitssalon",
    "Barbier",
];

const locationHints = ref([])

function search() {
    if (store.location.length === 0) {
        store.setError('location', __('Please select location'))
        return
    }

    localStorage.setItem('location', store.location)

    router.get(route("search", { city: store.location, category: store.search, date: store.date }));
}

function selectSearch(category) {
    store.search = category;
    closeHints()
}

function selectLocationHint(location) {
    store.location = location;
    showLocationHints.value = false
}

const showHints = ref(false);
const showLocationHints = ref(false);
function closeHints() {
    showHints.value = false;
}
function closeLocationHints() {
    showLocationHints.value = false;
}

function locatorButtonPressed() {
    navigator.geolocation.getCurrentPosition(
        (position) => {
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);

            getAddress(position.coords.latitude, position.coords.longitude);
        },
        (error) => {
            console.log(error.message);
        }
    );
}

async function getAddress(lat, long) {
    const resp = await axios.get(route('api.city.coordinates', {latitude: lat, longitude: long}));
    console.log(resp);

    store.location = resp.data.city;
    showLocationHints.value = false
}

watch(() => store.location, (newVal) => {
    if (!newVal.length) {
        localStorage.removeItem('location')
    }

    if (store.errors.location && store.location.length) {
        store.errors.location = null
    }

    if (newVal.trim().length > 1) {
        axios.get(route('api.city.search', {
            _query: {
                name: newVal
            }
        }))
            .then(response => {
                locationHints.value = response.data.map(city => city.name)
            })
    }
})
</script>

<template>
        <div class="border rounded-full shrink-0 px-8 py-2 cursor-pointer" @click="expandSearch">
            <div class="bold text-dark">{{ store.search }}</div>
            <div class="flex items-center space-x-2">
                <div class="text-sm ">{{ store.date ?? __('today') }}</div>
                <div>&bull;</div>
                <div class="text-sm ">{{ store.location }}</div>
            </div>
        </div>
<!--    v-on-click-outside="() => (isExpanded = false)"-->
       <div v-if="isExpanded" class="expanded-form" >

           <form class="rounded-3 relative p-4 pe-md-5 pb-5 pb-md-4 mx-auto" style="max-width: 850px">
               <div class="row g-4 align-items-center">
                   <div class="col-lg-4 relative">
                       <div class="form-control-border form-control-transparent form-fs-md d-flex">
                           <div class="w-100 relative">
                               <div class="absolute top-3 left-3">
                                   <SearchIcon class="w-5 h-5" />
                               </div>
                               <input type="search" class="form-control filled pl-10 h-12" :placeholder="__('What are you looking for?')"
                                      v-model="store.search" @click="showHints = true" />
                           </div>
                       </div>

                       <div class="relative">
                           <ul class="mt-2 dropdown-menu input-dropdown shadow rounded w-100" :class="{ 'block': showHints }"
                               v-on-click-outside="closeHints" v-if="showHints">
                               <li v-for="c in categories" class="" :key="c" @click.prevent="selectSearch(c)">
                                   <a class="dropdown-item" href="#"> {{ c }}</a>
                               </li>
                           </ul>
                       </div>
                   </div>
                   <!-- Location -->
                   <div class="col-lg-4">
                       <div class="relative">
                           <div class="absolute top-3 left-3">
                               <MapPinIcon class="w-5 h-5" @click="locatorButtonPressed" />
                           </div>
                           <div class="form-control-border form-control-transparent form-fs-md">
                               <input type="search" class="form-control filled pl-10 h-12" placeholder="Aktueller Standort"
                                      v-model="store.location" @click="showLocationHints = true" />
                               <div v-if="store.errors.location" class="text-red-600 font-xs">{{ store.errors.location }}</div>
                               <div v-if="false">
                                   <p class="">Sie haben keinen Zugriff auf Ihren aktuellen Standort erlaubt. Für die genauesten
                                       Suchergebnisse passen Sie bitte die Standorteinstellungen an.</p>
                               </div>
                               <div class="relative" v-if="showLocationHints">
                                   <ul class="mt-2 dropdown-menu input-dropdown shadow rounded w-100"
                                       :class="{ 'block': showLocationHints }" v-on-click-outside="closeLocationHints"
                                       v-if="showLocationHints">
                                       <li>
                                           <a class="dropdown-item flex items-center" href="#"
                                              @click.prevent="locatorButtonPressed">
                                               <MapPinIcon class="w-5 h-5 mr-2" />
                                               {{ __('current location') }}
                                           </a>
                                       </li>
                                       <li v-for="c in locationHints" class="" :key="c" @click.prevent="selectLocationHint(c)">
                                           <a class="dropdown-item" href="#"> {{ c }}</a>
                                       </li>
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </div>
                   <!-- date -->
                   <div class="col-lg-4">
                       <div class="relative">
                           <div class="absolute top-3 left-3">
                               <CalendarDaysIcon class="w-5 h-5" />
                           </div>
                           <div class="form-control-border form-control-transparent form-fs-md">

                               <VueFlatpickr v-model="store.date" />
                               <div v-if="store.errors.date" class="text-red-600 font-xs">{{ store.errors.date }}</div>
                               <!-- <DatePicker /> -->
                               <!-- <input type="date" class="form-control" placeholder="When" v-model="form.date" /> -->
                           </div>
                       </div>
                   </div>
               </div>
               <!-- Button -->
               <div class="btn-position-md-middle">
                   <a class="icon-lg btn btn-round btn-primary mb-0" href="#" @click.prevent="search">
                       <SearchIcon class="w-6 h-6"/>
                   </a>
               </div>
           </form>

           <div class="absolute top-1 right-1">
               <button class="btn bg-white text-dark" @click.prevent="isExpanded = false">
                   <IconClose class="w-4 h-4"/>
               </button>
           </div>
       </div>


</template>

<style scoped>
.expanded-form {
    position: absolute;
    top: 1em;
    background: white;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9994;
    transition: all 0.3s;
    transform: translateY(0);
}
</style>
