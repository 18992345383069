<script setup>
import DisclosureCss from "@/Components/DisclosureCss.vue";
import {Link} from "@inertiajs/vue3";
import {vOnClickOutside} from '@vueuse/components'
import {
    CalendarDaysIcon,
    CalendarIcon,
    UsersIcon,
    BriefcaseIcon,
    HomeIcon,
    BeakerIcon,
    StarIcon,
    Cog8ToothIcon,
    ArrowRightOnRectangleIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
} from "@heroicons/vue/24/outline";
import {ref, watch} from "vue";
import DiamondIcon from "@/Components/Icons/DiamondIcon.vue";

const props = defineProps({
    currentRoute: String,
    show: {
        type: Boolean
    }
});
const emit = defineEmits(['close']);

const isCalendarPage = ref(props.currentRoute === 'company-calendar.index')
const isNarrow = ref(isCalendarPage.value || false);

function close() {
    emit('close')
}
watch(() => props.currentRoute, (nv, ov) => {
    isCalendarPage.value = (nv === 'company-calendar.index')
    isNarrow.value = isCalendarPage.value

    if (nv !== ov) {
        emit('close')
    }
})
</script>

<template>
    <nav class="navbar sidebar navbar-expand-xl navbar-light z-9999" :class="{'narrow': isNarrow, 'expanded' : show}">
        <!-- Navbar brand for xl START -->
        <div class="d-flex align-items-center">
            <Link class="navbar-brand flex items-center" href="/">
                <img class="light-mode-item navbar-brand-item" src="/images/logo_3_sm.jpg" alt="logo"/>
                <img class="dark-mode-item navbar-brand-item" src="/images/logo_3_sm.jpg" alt="logo"/>
                <span class="bold ml-2">{{ $page.props.config['app.name'] }}</span>
            </Link>
        </div>
        <!-- Navbar brand for xl END -->

        <div
            class="offcanvas offcanvas-start flex-row custom-scrollbar h-100 os-host os-theme-dark os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-scrollbar-vertical-hidden os-host-transition z-1045"
            :class="{ 'show': show }" data-bs-backdrop="true" tabindex="-1" v-on-click-outside="close"
            id="offcanvasSidebar">
            <div class="os-resize-observer-host observed">
                <div class="os-resize-observer" style="left: 0px; right: auto"></div>
            </div>
            <div class="os-size-auto-observer observed" style="height: calc(100% + 1px); float: left">
                <div class="os-resize-observer"></div>
            </div>
            <!--/*                <div class="os-content-glue" style="width: 260px; margin: 0px; max-width: 100%; height: 1231px;"></div>*/-->
            <div class="os-padding">
                <div class="os-viewport os-viewport-native-scrollbars-invisible os-viewport-native-scrollbars-overlaid"
                     tabindex="-1" style="overflow: visible">
                    <div class="os-content" style="
                            padding: 0px;
                            height: 100%;
                            width: 100%;
                            float: left;
                        ">
                        <div class="offcanvas-body sidebar-content d-flex flex-column pt-4">
                            <!-- Sidebar menu START -->
                            <ul class="navbar-nav flex-column" id="navbar-sidebar">
                                <!-- Menu item -->
                                <li class="nav-item">
                                    <Link :href="route('dashboard')" class="nav-link"
                                          :class="{ 'active': route().current('dashboard') }">
                                        <HomeIcon class="w-6 h-6 me-2"/>
                                        <span class="nav-text">Dashboard</span>
                                    </Link>
                                </li>

                                <!-- Title -->
                                <li class="nav-item ms-2 my-2">{{ __('Pages') }}</li>

                                <li class="nav-item">
                                    <Link class="nav-link" :href="route('company-calendar.index')">
                                        <CalendarIcon class="w-6 h-6 me-2"/>
                                        <span class="nav-text">Kalendar</span>
                                    </Link>
                                </li>

                                <!-- Menu item -->
                                <li class="nav-item">
                                    <Link class="nav-link" :href="route('company-booking.index')"
                                          :class="{ 'active': route().current('company-booking.*') }">
                                        <CalendarDaysIcon class="w-6 h-6 me-2"/>
                                        <span class="nav-text">Buchungen</span>
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" :href="route('company-plan.index')"
                                          :class="{ 'active': route().current('company-plan.*') }">
                                        <DiamondIcon class="w-6 h-6 me-2"/>
                                        <span class="nav-text">Plan</span>
                                    </Link>
                                </li>
                                <li class="nav-item">
                                    <Link class="nav-link" :href="route('company-review.index')"
                                          :class="{ 'active': route().current('company-review.*') }">
                                        <StarIcon class="w-6 h-6 me-2"/>

                                        <span class="nav-text">Bewertungen</span>

                                    </Link>
                                </li>

                                <!-- Menu item -->
                                <li class="nav-item">
                                    <Link class="nav-link" :href="route('company-client.index')"
                                          :class="{ 'active': route().current('company-client.*') }">
                                        <UsersIcon class="w-6 h-6 me-2"/>
                                        <span class="nav-text">Kunden</span>
                                    </Link>
                                </li>

                                <!-- Menu item -->
                                <li class="nav-item">
                                    <DisclosureCss :is-active="route().current('company-employee*')"
                                    >
                                        <template #default>
                                            <div class="nav-link w-100"
                                                 :class="{ 'active': route().current('company-employee*') }">
                                                <BriefcaseIcon class="w-6 h-6 me-2"/>
                                                <span class="nav-text">{{ __('Employees') }}</span>
                                            </div>
                                        </template>
                                        <template #content>
                                            <ul class="nav flex-column">
                                                <li class="nav-item">
                                                    <Link class="nav-link" :href="route('company-employee.index')"
                                                          :class="{ 'active': route().current('company-employee.index') }">
                                                        {{ __('Employee List') }}
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link class="nav-link" :href="route('company-employee.create')">
                                                        {{ __('Add new Employee') }}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </template>
                                    </DisclosureCss>
                                </li>

                                <!-- Menu item -->
                                <li class="nav-item">
                                    <DisclosureCss :is-active="route().current('company-service*')">
                                        <template #default>
                                            <div class="nav-link w-100"
                                                 :class="{ 'active': route().current('company-service*') }">
                                                <BeakerIcon class="w-6 h-6 me-2"/>
                                                <span class="nav-text">{{ __('Services') }}</span>
                                            </div>
                                        </template>
                                        <template #content>
                                            <ul class="nav flex-column">
                                                <li class="nav-item">
                                                    <Link class="nav-link" :href="route('company-service.index')"
                                                          :class="{ 'active': route().current('company-service.index') }">
                                                        {{ __('Services List') }}
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link class="nav-link"
                                                          :href="route('company-service-category.index')">
                                                        {{ __('Service categories') }}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </template>
                                    </DisclosureCss>
                                </li>

                                <!-- Menu item -->
                                <li class="nav-item">
                                    <DisclosureCss :is-active="route().current('company-settings*')">
                                        <template #default>
                                            <div class="nav-link w-100"
                                                 :class="{ 'active': route().current('company-settings*') }">
                                                <Cog8ToothIcon class="w-6 h-6 me-2"/>
                                                <span class="nav-text">{{ __('Settings') }}</span>
                                            </div>
                                        </template>
                                        <template #content>
                                            <ul class="nav flex-column">
                                                <li class="nav-item">
                                                    <Link class="nav-link" :href="route('company-settings.edit')"
                                                          :class="{ 'active': route().current('company-settings.edit') }">
                                                        {{ __('Company settings') }}
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link class="nav-link" :href="route('company-image.index')">
                                                        {{ __('Gallery') }}
                                                    </Link>
                                                </li>
                                                <li class="nav-item">
                                                    <Link class="nav-link" :href="route('company-settings.workingHours')"
                                                          :class="{ 'active': route().current('company-settings.workingHours') }">
                                                        {{ __('Working hours') }}
                                                    </Link>
                                                </li>
                                            </ul>
                                        </template>
                                    </DisclosureCss>
                                </li>

                            </ul>
                            <!-- Sidebar menu end -->

                            <!-- Sidebar footer START -->
                            <div
                                class="navbar-nav d-flex align-items-center justify-content-between text-primary-hover mt-auto w-100">
                                <Link :href="route('logout')" method="post" as="button"
                                      class="nav-link outline-none border-none text-left w-100">
                                    <ArrowRightOnRectangleIcon class="h-6 w-6 me-2"/>
                                    <span class="nav-text">{{ __('Logout') }}</span>
                                </Link>
                                <!--                                <a class="h6 mb-0 text-body" href="" data-bs-toggle="tooltip"-->
                                <!--                                    data-bs-placement="top" aria-label="Settings">-->
                                <!--                                    <i class="bi bi-gear-fill"></i>-->
                                <!--                                </a>-->
                            </div>
                            <!-- Sidebar footer END -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="os-scrollbar os-scrollbar-horizontal os-scrollbar-unusable os-scrollbar-auto-hidden">
                <div class="os-scrollbar-track os-scrollbar-track-off">
                    <div class="os-scrollbar-handle" style="width: 100%; transform: translate(0px)"></div>
                </div>
            </div>
            <div class="os-scrollbar os-scrollbar-vertical os-scrollbar-unusable os-scrollbar-auto-hidden">
                <div class="os-scrollbar-track os-scrollbar-track-off">
                    <div class="os-scrollbar-handle" style="height: 100%; transform: translate(0px)"></div>
                </div>
            </div>
            <div class="absolute bottom-0">
                <button class="btn btn-sm btn-outline-light" @click.prevent="isNarrow = !isNarrow">
                    <ArrowLeftIcon class="h-5 w-5" v-if="!isNarrow"/>
                    <ArrowRightIcon class="h-5 w-5" v-if="isNarrow"/>
                </button>
            </div>
            <!-- <div class="offcanvas-backdrop fade show" v-if="show"></div> -->
        </div>
    </nav>
</template>
