<script setup>

import SearchIcon from "@/Icons/SearchIcon.vue";
import {CalendarIcon, HomeIcon, HeartIcon} from "@heroicons/vue/24/outline";
import UserCircle from "@/Icons/UserCircle.vue";
import {router, usePage} from "@inertiajs/vue3";

function scrollToSearch() {
    if (!route().current('homepage')) {
        router.get('/')
    }

    const element = document.getElementById("searchRow");

    element.scrollIntoView({behavior: "smooth"});
}

const homeRoute = usePage().props.auth.user ? route('dashboard') : route('homepage')
</script>

<template>

    <div class="fixed-bottom bg-dark h-16" style="border-top-left-radius: 16px; border-top-right-radius: 16px" >
        <div class="flex justify-around items-center text-xs bold pt-2 pb-2 h-100">
            <Link :href="homeRoute" class="text-white-70">
                <div class="flex flex-col items-center">
                    <HomeIcon class="w-6 h-6"/>
                    <span class="mt-1">{{ __('Home') }}</span>
                </div>
            </Link>

            <a @click.prevent="scrollToSearch" href="#searchRow" class="text-white-70">
                <div class="flex flex-col items-center">
                    <SearchIcon class="w-6 h-6"/>
                    <span class="mt-1"> {{ __('Search') }}</span>
                </div>
            </a>

            <Link :href="route('user.booking.index')" class="text-white-70">
                <div class="flex flex-col items-center">
                    <CalendarIcon class="w-6 h-6"/>
                    <span class="mt-1">{{ __('Bookings') }}</span>
                </div>
            </Link>

            <Link :href="route('user.favourites.index')" class="text-white-70">
                <div class="flex flex-col items-center">
                    <HeartIcon class="w-6 h-6"/>
                    <span class="mt-1">{{ __('Favourites') }}</span>
                </div>
            </Link>

            <Link :href="route('user.settings.index')" class="text-white-70">
                <div class="flex flex-col items-center">
                    <UserCircle class="h-6 w-6"/>
                    <span class="mt-1">{{ __('Profile') }}</span>
                </div>
            </Link>

        </div>
    </div>
</template>

<style scoped>

</style>
