<script setup>
import { twMerge } from 'tailwind-merge'
import { computed } from "vue";

const props = defineProps(['class'])
const classes = computed(() => {
    return twMerge('w-6 h-6', props.class)
})
</script>

<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
        :class="classes">
        <path stroke-linecap="round" stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </svg>
</template>