export const deviceType = () => {
    const platform = (navigator?.userAgentData?.platform || navigator?.userAgent || navigator?.platform || 'unknown').toLowerCase()

    if (/(android|webos|iphone|ipad|ipod|blackberry|windows phone)/im.test(platform)) {
        return 'mobile';
    } else if (/mac|win|linux/i.test(platform)) {
        return 'desktop';
    } else if (/tablet|ipad/i.test(platform)) {
        return 'tablet';
    } else {
        return 'unknown';
    }
}

export const isMobile = () => deviceType() === 'mobile'

// export { deviceType, isMobile }
