import { defineStore } from "pinia";

export const useSearchStore = defineStore("search", {
    state: () => ({
        search: null,
        location: null,
        date: null,
        errors: {},
    }),

    actions: {
        setCompanySlug(slug) {
            this.companySlug = slug;
        },

        setError(key, message) {
            this.errors[key] = message;
        },
    },

    getters: {

    },
});
