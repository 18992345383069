<script setup>
import NavbarTop from "@/Components/Layout/CompanyLayout/NavbarTop.vue";
import SidebarLeft from "@/Components/Layout/CompanyLayout/SidebarLeft.vue";
import { ref, useAttrs } from "vue";
import FlashMessages from "@/Components/Forms/FlashMessages.vue";

const sidebarShowOnMobile = ref(false)

const attrs = useAttrs()
const flash = attrs.flash;

function onShowSidebarMobile() {
    sidebarShowOnMobile.value = true
}
function onClose() {
    sidebarShowOnMobile.value = false
}
</script>

<template>
    <main>
        <!-- Sidebar START -->
        <SidebarLeft :show="sidebarShowOnMobile" @close="onClose" :current-route="route().current()" />
        <!-- Sidebar END -->

        <!-- Page content START -->
        <div class="page-content">
            <!-- Top bar START -->
            <NavbarTop @show-sidebar-mobile="onShowSidebarMobile" />
            <!-- Top bar END -->

            <!-- Page main content START -->
            <div class="page-content-wrapper p-xxl-4">
                <FlashMessages :flash="flash" />

                <slot />
            </div>
            <!-- Page main content END -->
        </div>
        <!-- Page content END -->
    </main>
</template>
