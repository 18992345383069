import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat)
export function formatDate(date, format = "DD.MM.YYYY", fromFormat = null) {
    if (fromFormat) {
        return dayjs(date, fromFormat).locale("de").format(format);
    }
    return dayjs(date).locale("de").format(format);
}

export function formatTime(date, format = "HH:mm", fromFormat = null) {
    if (fromFormat) {
        return dayjs(date, fromFormat).format(format);
    }
    return dayjs(date).format(format);
}

