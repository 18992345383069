<script setup>
import TopNavbar from '@/Components/Layout/TopNavbar.vue';
import {isMobile} from "@/Composables/deviceUtils";
import MobileNavbarBottom from "@/Components/Layout/MobileNavbarBottom.vue";
import {usePage} from "@inertiajs/vue3";

const isM = isMobile()
const isU = usePage().props.auth.user?.type === 'U'
</script>

<template>
    <div class="h-full relative">
        <TopNavbar :current-page="route().current()"/>

        <main>
            <slot/>
        </main>

        <MobileNavbarBottom v-if="isM && isU" />
    </div>
</template>
