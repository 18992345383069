import "./bootstrap";
import "../css/app.css";

import { createApp, h } from "vue";
import { createInertiaApp, Link } from "@inertiajs/vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { ZiggyVue } from "../../vendor/tightenco/ziggy/dist/vue.m";
import GuestLayout from "./Layouts/GuestLayout.vue";
import CompanyLayout from "@/Layouts/CompanyLayout.vue";
import EmployeeLayout from "@/Layouts/EmployeeLayout.vue";
import { createPinia } from "pinia";
import { translations } from "./Mixins/translations";
import * as Sentry from "@sentry/vue";

const pinia = createPinia();

const appName =
    window.document.getElementsByTagName("title")[0]?.innerText || "Laravel";

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ).then((page) => {
            page.default.layout ??= GuestLayout;
            // console.log(name)
            if (name.startsWith("Company") || name === "Dashboard" || name.startsWith('Calendar')) {
                page.default.layout = CompanyLayout;
            }

            if (name.startsWith("EmployeeDashboard") || name === "Dashboard") {
                page.default.layout = EmployeeLayout;
            }
            // else if (name.startsWith("UserDashboard") || name == "DashboardUser") {
            //     page.default.layout = UserDashboardLayout;
            // }
            return page;
        }),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        // Sentry.init({
        //     app,
        //     dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
        //     integrations: [
        //         new Sentry.BrowserTracing({
        //             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //             tracePropagationTargets: ["localhost", '127.0.0.1:8005', /^https:\/\/demo\.buchtermin\.com/],
        //         }),
        //         new Sentry.Replay({
        //             maskAllText: false,
        //             blockAllMedia: false,
        //         }),
        //     ],
        //     // Performance Monitoring
        //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
        //     // Session Replay
        //     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        //     logErrors: true,
        // });

        app.use(plugin)
            .use(pinia)
            .use(ZiggyVue)
            .mixin(translations)
            .component("Link", Link)
            .mount(el);

        return app;
    },
    progress: {
        color: "#5949ff",
    },
});

const clickOutside = {
    mounted(el, binding, vnode, prevVnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted(el, binding, vnode, prevVnode) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
};
