<script setup>
import { ref } from 'vue';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { German } from 'flatpickr/dist/l10n/de.js';

const date = ref(null);

const config = ref({
    // wrap: true, // set wrap to true only when using 'input-group'
    altFormat: 'j F Y',
    altInput: true,
    defaultDate: new Date(),
    dateFormat: 'Y-m-d',
    minDate: 'today',
    locale: German, // locale for this instance only
});
</script>

<template>
    <flat-pickr v-model="date" :config="config" class="form-control filled pl-10 h-12" placeholder="Aktueller Datum" />
</template>

<style>
.flatpickr-day.selected {
    background: var(--primary-color);
    border-color: var(--primary-color);
}

.flatpickr-day.selected:hover {
    background: var(--dark);
    border-color: var(--dark);
}

.flatpickr-day:hover {
    color: var(--dark);
}
</style>
