<script setup>
import { ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import { ArrowRightOnRectangleIcon, CalendarDaysIcon, ChartBarIcon, Cog8ToothIcon, ListBulletIcon } from '@heroicons/vue/24/outline'
import {useUser} from "@/Composables/useUser.js";

const show = ref(false);
const user = useUser();
</script>

<template>
    <!-- Profile dropdown START -->
    <li class="nav-item ms-3 dropdown">
        <!-- Avatar -->
        <a @click="show = !show" v-on-click-outside="() => (show = false)" class="avatar avatar-sm p-0" href="#"
            id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static"
            data-bs-toggle="dropdown" aria-expanded="false">
            <img class="avatar-img rounded-2" :src="user.img_path" alt="avatar" />
        </a>

        <ul class="dropdown-menu dropdown-animation dropdown-menu-end shadow pt-3" :class="{ block: show }"
            aria-labelledby="profileDropdown">
            <!-- Profile info -->
            <li class="px-3 mb-3">
                <div class="d-flex align-items-center">
                    <!-- Avatar -->
                    <div class="avatar me-3">
                        <img class="avatar-img rounded-circle shadow" :src="user.img_path" alt="avatar" />
                    </div>
                    <div>
                        <a class="h6 mt-2 mt-sm-0" href="#">{{ user.name }}</a>
                        <p class="small m-0">
                            {{ user.email }}
                        </p>
                    </div>
                </div>
            </li>

            <!-- Links -->
            <li>
                <hr class="dropdown-divider" />
            </li>
            <li>
                <Link class="dropdown-item" :href="route('dashboard')">
                <ChartBarIcon class="h-6 w-6 mr-2" />
                {{ __('Dashboard') }}
                </Link>
            </li>
            <li>
                <Link class="dropdown-item" :href="route('booking.index')">
                <ListBulletIcon class="h-6 w-6 mr-2" />
                {{ __('Bookings') }}
                </Link>
            </li>
            <li>
                <Link class="dropdown-item" :href="route('company-calendar.index')" v-if="user.type === 'C'">
                <CalendarDaysIcon class="h-6 w-6 mr-2" />
                {{ __('Calendar') }}
                </Link>
            </li>
            <li>
                <Link class="dropdown-item" :href="route('a.settings')">
                <Cog8ToothIcon class="h-6 w-6 mr-2" />
                {{ __('Settings') }}
                </Link>
            </li>

            <li>
                <Link :href="route('logout')" method="post" as="button" class="dropdown-item bg-danger-soft-hover">
                <ArrowRightOnRectangleIcon class="h-6 w-6 mr-2" />
                {{ __('Log Out') }}
                </Link>
            </li>
        </ul>
    </li>
    <!-- Profile dropdown END -->
</template>

