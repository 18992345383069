<script setup>
import { ref } from "vue";
import { vOnClickOutside } from "@vueuse/components";
import BellIcon from "@/Icons/BellIcon.vue";
import {router, usePage} from "@inertiajs/vue3";
import dayjs from "dayjs";
// import * as relativeTime from 'dayjs/plugin/relativeTime'; // old
import relativeTime from 'dayjs/plugin/relativeTime';
import "dayjs/locale/de";
import {formatDate} from "@/Composables/formatDate.js";
import {useUser} from "@/Composables/useUser.js";

const show = ref(false);
const user = useUser();
const notifications = ref(usePage().props.auth.notifications);

dayjs().locale('de');
dayjs.extend(relativeTime);

function markAsRead() {
  axios.post(route('notifications.mark-as-read'))
      .then(() => {
        notifications.value = []
      })
}

function showNotification(n) {
    axios.post(route('notification.single.mark-as-read'), {
        id: n.id
    })

    router.get(route('company-booking.show', n.data.booking.uuid))

    notifications.value = notifications.value.filter(noti => noti.id !== n.id)
}
</script>

<template>
    <li class="nav-item dropdown ms-0 ms-md-3" tabindex="0">
        <!-- Notification button -->
        <a class="nav-notification btn btn-light p-0 mb-0" href="#" @click.prevent="show = !show"
            role="button" data-bs-toggle="dropdown" aria-expanded="false"
            data-bs-auto-close="outside">
            <BellIcon />
        </a>
        <!-- Notification dote -->
        <span class="notif-badge animation-blink" v-if="notifications?.length"></span>

        <!-- Notification dropdown menu START -->
        <div class="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md shadow-lg p-0"
             v-on-click-outside="() => (show = false)"
            :class="{ block: show }">
            <div class="card bg-transparent">
                <!-- Card header -->
                <div class="card-header bg-transparent d-flex flex-wrap justify-content-between align-items-center border-bottom">
                    <h6 class="m-0  flex-shrink-0">
                        {{ __('Notifications') }}
                        <span class="badge bg-danger bg-opacity-10 text-danger ms-2">{{ notifications?.length }}</span>
                    </h6>
                    <a class="small" href="#" @click.prevent="markAsRead">{{ __('Clear all') }}</a>
                </div>

                <!-- Card body START -->
                <div class="card-body p-0">
                    <ul class="list-group list-group-flush list-unstyled p-2">
                        <!-- Notification item -->
                        <li v-for="n in notifications" :key="n.id">
                            <a @click.prevent="showNotification(n)"
                                class="list-group-item list-group-item-action rounded notif-unread border-0 mb-1 p-3">
                                <div class="mb-2 flex justify-between items-center">
                                  <span class="h6 m-0">{{ n.data.title }}</span>
                                  <span class="small">{{ dayjs(n.created_at).fromNow() }}</span>
                                </div>
                                <p class="mb-0 small flex">
                                  {{ n.data.booking.title }} &bull;
                                 {{ __('Employee') }}:  {{ n.data.employee.name }} <br>
                                  {{ `${formatDate(n.data.booking.date, 'DD.MM.YYYY')} ${formatDate(n.data.booking.start_time, 'HH:mm')}` }}
                                </p>

                            </a>
                        </li>
                        <li v-if="notifications.length === 0">
                          <a href="#"
                             class="list-group-item list-group-item-action rounded border-0 mb-1 p-3">
                            {{ __('No new notifications') }}
                          </a>
                        </li>
                    </ul>
                </div>
                <!-- Card body END -->

                <!-- Card footer -->
<!--                <div class="card-footer bg-transparent text-center border-top">-->
<!--                    <Link href="#" class="btn btn-sm btn-link mb-0 p-0">{{ __('See all activity') }}</Link>-->
<!--                </div>-->
            </div>
        </div>
        <!-- Notification dropdown menu END -->
    </li>
</template>
